<template>
  <!-- 历年数据：每张表在两个月份之间每月的用电量 -->
  <div id="app">
    <div class="container">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            v-model="timePickerValue"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          >
          </el-date-picker>
        </el-col>

        <!-- 查询按钮 -->
        <el-col :span="1.5">
          <el-button type="primary" @click="getHistoryData">查 询</el-button>
        </el-col>

        <!-- 导出excel表格 -->
        <el-col :span="3">
          <download-excel
            types="xls"
            :fields="electricExcelfields"
            :data="tableData"
          >
            <el-button type="primary">导出Excel</el-button>
          </download-excel>
        </el-col>
      </el-row>

      <div class="content">
        <!-- 表格部分 -->
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData"
          height="735"
          border
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            label="编号"
            prop="coding"
            align="center"
            width="140"
          ></el-table-column>
          <el-table-column
            label="IMEI"
            prop="deviceId"
            align="center"
            width="180"
          ></el-table-column>
          <el-table-column
            label="设备名称"
            prop="deviceName"
            align="center"
            width="180"
          ></el-table-column>

          <el-table-column v-for="(item, i) in timeList" :key="i" :label="item" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.valueList[i] }} m³</div>
            </template>
          </el-table-column>


          <el-table-column label="累计" align="center" width="150">
          </el-table-column>
        </el-table>
      </div>
      <div class="total">
        合计：(Kw/h)<span>{{ totalElectricUse }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// 获取公司id及名称
import companySystem from "@/api/managementApi/CompanyInfo";
// 获取表格数据
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";

export default {
  components: {},
  props: {},
  data() {
    return {
      // 合计行显示
      totalElectricUse: 0,
      // 导出表格数据（有日期时间需要对日期数组进行forEach遍历）
      electricExcelfields: {
        编号: "coding",
      },
      // 加载页面的展示
      loading: true,
      //页面默认选择时间
      timePickerValue: [],
      // 表格双向绑定数据
      tableData: [],
      // 选择公司下拉框绑定数据
      companyList: [],
      timeList: [],
    };
  },
  created() {
    // 设置默认时间
    this.setQueryData();
    // 获取表格数据
    this.getHistoryData()
  },

  methods: {
    // 获取历年数据
    getHistoryData() {
      // 调用接口(假数据)
      for(let i = 0;i<20;i++){
        let obj = {
          deviceId: `123400${i}`,
          coding:`a_${i}`,
          deviceName: `模拟设备0${i}`,
          timeList: [
            "2023-04-20",
            "2023-04-21",
            "2023-04-22",
            "2023-04-23",
            "2023-04-24",
            "2023-04-25",
            "2023-04-26",
          ],
          valueList: [
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
          ],
        }
        this.tableData.push(obj)
      }
      // 时间数组赋值,获取表头
      this.timeList = this.tableData[0].timeList;
    },

    // 复选框选中添加用水量统计
    handleSelectionChange(val) {
      console.log(val);
      this.totalElectricUse = 0;
      for (let i = 0; i < val.length; i++) {
        this.totalElectricUse += Number(val[i].periodDifference);
      }
    },
    // 设置默认显示日期的方法
    setQueryData() {
      this.timePickerValue[0] = this.$moment().format("YYYY-MM-DD");
      this.timePickerValue[1] = this.$moment().format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
// 导入表格css
@import "../../../assets/css/tableCss.scss";
// 导入led样式字体
@font-face {
  font-family: led regular;
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  position: relative;
  box-sizing: border-box;
  padding: 90px 10px 0 10px;
  height: 100%;
  .content {
    height: 70%;
    padding: 10px 0;
  }
  .total {
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    height: 60px;
    color: aqua;
    line-height: 60px;
    font-size: 20px;
    width: 95%;
    span {
      font-family: led regular;
      float: right;
      font-size: 40px;
    }
  }
}
</style>
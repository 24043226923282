// 实时值历史表api
import request from '../../../utils/request'
const api_name = '/device-history-value'

export default {
  getOneByDeviceHistoryStatusId(id) { //根据状态历史表中的id查询实时值历史表中的一条数据
    return request({
      url: `${api_name}` + '/getOneByDeviceHistoryStatusId/' + id,
      method: 'get'
    })
  },
  getAll(currentPage, type, superDeviceId, superValueName) { //分页查询，设备类型条件查询
    let typeName = "";
    let deviceId = "";
    let valueName = "";

    if (type != "") {

      typeName = '&typeName=' + type
    }
    if (superDeviceId != "") {
      deviceId = '&deviceId=' + superDeviceId
    }
    if (superValueName != "") {
      valueName = '&valueName=' + superValueName
    }
    return request({
      url: `${api_name}` + '/getAll?number=' + currentPage + typeName + deviceId + valueName,
      method: 'get'
    })
  },
  getAllHistoryValue() {
    return request({
      url: `${api_name}` + '/getAllHistoryValue',
      method: 'get'
    })
  },
  getDayValue(deviceId, value, createTime, typeId) {
    return request({
      url: `${api_name}` + '/getDayValue',
      method: 'post',
      data: {
        deviceId: deviceId,
        value: value,
        createTime: createTime,
        typeId: typeId
      }
    })
  },

  // 获取设备分级列表
  getHierarchicalList(deviceTypeId,ciId) {
    let url = `${api_name}/getHierarchicalList/${deviceTypeId}`
    if(ciId!=undefined && ciId!=null && ciId!=""){
      url += "?ciId="+ciId
    }
    return request({
      url: url,
      method: 'get'
    })
  },

  // 获取水表设备的用水量数据
  getWaterMeterData(reguest) {
    return request({
      url: `${api_name}/getWaterMeterData`,
      method: 'post',
      data: reguest
    })
  },

  // 获取水表读数
  getMeterReading(obj) {
    return request({
      url: `${api_name}/getMeterReading`,
      method: 'post',
      data: obj
      })
  },

  // 获取获取水表历史记录
  getWaterMeterHistory(obj) {
    return request({
      url: `${api_name}/getWaterMeterHistory`,
      method: 'post',
      data: obj
    })
  },

  // 抄表数据
  getWaterMeterDataAndInfo(obj){
    return request({
      url: `${api_name}/getWaterMeterDataAndInfo`,
      method: 'post',
      data: obj
    })
  },

  // 用水/用电历年数据设备编号信息
  getTypeAttribute(obj){
    return request({
      url: `${api_name}/getTypeAttribute`,
      method: 'post',
      data: obj
    })
  },



  // 获取用电的设备信息
  // getElectricMeterDevice(){
  //   return request({
  //     url: `${api_name}/getElectricMeterDevice`,
  //     method: 'post',
  //     data:{}
  //   })
  // },

  // 获取两日之间的用水量，以及这两日分别的水表读数
  getWaterMeterConsumption(obj){
    return request({
      url: `${api_name}/getWaterMeterConsumption`,
      method:'post',
      data:obj
    })
  },

  // 历史数据页面，按水表读数之差获得的用水量数据
  getMonthWaterData(obj){
    return request({
      url:`${api_name}/getMonthWaterData`,
      method:'post',
      data:obj
    })
  },
}